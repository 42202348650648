import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './Questions.css';

const API_URL = process.env.REACT_APP_API_URL;

const Questions = ({ assistant, assistantPrompt, businessDescription }) => {
  const [questionsState, setQuestionsState] = useState({
    questions: [],
    currentPage: 0,
    totalPages: 0,
  });

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async (retryCount = 0) => {
    try {
      const response = await axios.get(`${API_URL}/generate`, {
        params: { assistant, assistant_prompt: assistantPrompt, business_description: businessDescription },
      });
  
      if (Array.isArray(response.data.questions)) {
        setQuestionsState({
          questions: response.data.questions,
          currentPage: 0,
          totalPages: Math.ceil(response.data.questions.length / 5),
        });
      } else {
        console.warn('No valid question objects found:', response.data.questions);
        setQuestionsState({
          questions: [],
          currentPage: 0,
          totalPages: 0,
        });
      }
    } catch (error) {
      if (retryCount < 3) {
        console.warn(`Retrying to fetch questions... Attempt ${retryCount + 1}`);
        await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
        await fetchQuestions(retryCount + 1);
      } else {
        console.error('Error fetching questions:', error);
        alert('An error occurred while fetching questions. Please try again later.');
      }
    }
  };

  const handleGenerateMore = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`${API_URL}/more_questions`, {
        assistant,
        assistant_prompt: assistantPrompt,
        business_description: businessDescription,
        qa_pairs: questionsState.questions.map(q => ({
          question: q.question,
          answer: q.answer || ''
        })),
      });
  
      if (response.data.status === 'success') {
        const newPairs = response.data.qa_pairs || [];
        const totalQuestions = questionsState.questions.length + newPairs.length;
        const totalPages = Math.ceil(totalQuestions / 5);
        const newCurrentPage = Math.floor((totalQuestions - 1) / 5);
  
        setQuestionsState((prevState) => ({
          questions: [...prevState.questions, ...newPairs],
          currentPage: newCurrentPage,
          totalPages,
        }));
      } else {
        console.warn('No new questions generated.');
      }
    } catch (error) {
      console.error('Error generating more questions:', error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(`An error occurred: ${error.response.data.message}`);
      } else {
        alert('An error occurred while generating more questions. Please try again.');
      }
    }
  };
  

  const renderQuestions = () => {
    const { questions, currentPage } = questionsState;
    const startIndex = currentPage * 5;
    const endIndex = startIndex + 5;
    const visibleQaPairs = questions.slice(startIndex, endIndex);
  
    return visibleQaPairs.map((qaPair, index) => (
      <Card key={index} className="mb-4 question-card">
        <Card.Body>
          <Card.Title className="mb-3 question-text">{qaPair.question}</Card.Title>
          <Form.Group className="mb-4">
            <Form.Control
              as="textarea"
              name={`answer_${index + startIndex}`}
              rows={3}
              required
              value={qaPair.answer}
              onChange={(e) => {
                const updatedQuestions = [...questionsState.questions];
                updatedQuestions[index + startIndex].answer = e.target.value;
                setQuestionsState((prevState) => ({
                  ...prevState,
                  questions: updatedQuestions,
                }));
              }}
              className="answer-textarea"
            />
          </Form.Group>
        </Card.Body>
      </Card>
    ));
  };

  const { currentPage, totalPages } = questionsState;

  return (
    <Container className="questions-container">
      <h2 className="text-center mb-4">Optimize your AI Assistant</h2>
      <Form onSubmit={handleGenerateMore}>
        {questionsState.questions.length > 0 ? (
          renderQuestions()
        ) : (
          <p>No questions available. Please check the console for errors.</p>
        )}
        <Row className="justify-content-between mt-4">
          <Col md={4}>
            {currentPage > 0 && (
              <Button
                variant="outline-primary"
                onClick={() => setQuestionsState((prevState) => ({ ...prevState, currentPage: prevState.currentPage - 1 }))}
                className="back-button"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" /> Back
              </Button>
            )}
          </Col>
          <Col md={4} className="text-center">
            <Button variant="primary" type="submit" className="generate-button">
              Generate More <FontAwesomeIcon icon={faPlus} className="ms-2" />
            </Button>
          </Col>
          <Col md={4} className="text-end">
            {currentPage + 1 < totalPages && (
              <Button
                variant="outline-primary"
                onClick={() => setQuestionsState((prevState) => ({ ...prevState, currentPage: prevState.currentPage + 1 }))}
                className="continue-button"
              >
                Continue <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Questions;
