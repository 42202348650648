import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const PdfFunnel = ({ businessDescription, assistantPrompt }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      await axios.post(`${API_URL}/generate-pdf`, {
        email,
        businessDescription,
        assistantPrompt
      });

      setShowSuccess(true);
      setEmail('');
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('An error occurred while generating your PDF. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSuccess) {
    return (
      <Alert variant="success">
        Your PDF will be in your inbox soon! Check your email for "10 Ways to Maximize Your AI Assistant's Potential".
      </Alert>
    );
  }

  return (
    <div>
      <h4>Get Your Free PDF Guide</h4>
      <p>Receive "10 Ways to Maximize Your AI Assistant's Potential" tailored to your business needs.</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Generating...' : "Let's do it!"}
        </Button>
      </Form>
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </div>
  );
};

export default PdfFunnel;
