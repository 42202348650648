import React, { useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function WebScraper({ onSummaryGenerated, onError }) {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      console.log("Sending scrape request for URL:", url);
      const scrapeResponse = await axios.post(`${API_URL}/scrape`, { url });
      console.log("Scrape response:", scrapeResponse.data);
      const scrapedContent = scrapeResponse.data.content;

      if (!scrapedContent) {
        throw new Error("No content scraped from the website");
      }

      console.log("Sending summarize request with content length:", scrapedContent.length);
      const summaryResponse = await axios.post(`${API_URL}/summarize`, { content: scrapedContent });
      console.log("Summary response:", summaryResponse.data);
      const summary = summaryResponse.data.summary;

      onSummaryGenerated(summary);
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = 'An error occurred while processing your request.';
      if (error.response) {
        console.error('Error response:', error.response);
        errorMessage = `Server error: ${error.response.status}`;
        if (error.response.data && error.response.data.message) {
          errorMessage += ` - ${error.response.data.message}`;
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage = 'No response received from the server. Please check your internet connection.';
      }
      onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="web-scraper">
      <form onSubmit={handleSubmit}>
        <input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter website URL"
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Analyze Website'}
        </button>
      </form>
    </div>
  );
}

export default WebScraper;