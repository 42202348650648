import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaTools, FaClock, FaRocket, FaGlobe } from 'react-icons/fa';
import WebScraper from './WebScraper';
import './Home.css';

const API_URL = process.env.REACT_APP_API_URL;

function Home() {
  const [businessDescription, setBusinessDescription] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting business description:", businessDescription);
      const response = await axios.post(`${API_URL}/init`, { business_description: businessDescription });
      console.log("Init response:", response.data);
      // Store the business description in localStorage
      localStorage.setItem('businessDescription', businessDescription);
      navigate('/assistants');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while submitting. Please try again.');
    }
  };

  const handleSummaryGenerated = (summary) => {
    setBusinessDescription(summary);
    setError('');
  };

  const handleScrapingError = () => {
    setError("Bummer, we are unable to read your website. Do you mind adding the business description manually?");
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>AI Assistant Prompt Generator</h1>
        <p className="subtitle">Create custom AI assistants tailored to your business needs in minutes</p>
      </header>
      
      <main className="home-main">
        <section className="hero-section">
          <div className="hero-content">
            <h2>Supercharge Your Business with AI</h2>
            <p>Our AI Assistant Prompt Generator helps you create powerful, customized AI assistants that understand your business and can handle a wide range of tasks.</p>
            <a href="#get-started" className="cta-button">Get Started</a>
          </div>
          <div className="hero-image">
            <img src="/assistant.jpg" alt="AI Assistant" />
          </div>
        </section>

        <section className="features-section">
          <h2>Why Choose Our AI Assistant Prompt Generator?</h2>
          <div className="features-grid">
            <div className="feature-card">
              <FaTools className="feature-icon" />
              <h3>Tailored to Your Business</h3>
              <p>Create assistants that understand your unique business context and goals.</p>
            </div>
            <div className="feature-card">
              <FaClock className="feature-icon" />
              <h3>Save Time and Resources</h3>
              <p>Quickly generate AI assistants without extensive coding or AI expertise.</p>
            </div>
            <div className="feature-card">
              <FaRocket className="feature-icon" />
              <h3>Versatile Applications</h3>
              <p>From customer support to market research, our AI assistants can handle it all.</p>
            </div>
          </div>
        </section>

        <section id="get-started" className="get-started-section">
          <h2>Get Started in 3 Easy Steps</h2>
          <div className="steps-container">
            <div className="step">
              <div className="step-number">1</div>
              <h3>Describe Your Business</h3>
              <p>Provide a brief description of your business and its needs, or let us analyze your website.</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <h3>Choose an Assistant Type</h3>
              <p>Select from our range of pre-made assistant templates.</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <h3>Customize and Generate</h3>
              <p>Answer a few questions to tailor the AI assistant to your needs.</p>
            </div>
          </div>
        </section>

        <section className="start-now-section">
          <div className="website-analysis">
            <h3>Let's Begin by Analyzing Your Website</h3>
            <p>Enter your business website URL below, and we'll attempt to automatically capture a detailed business description for you. If the automatic analysis doesn't capture everything or if it's inaccurate, you can easily fill in the details manually. The more information you provide, the better we can tailor your AI assistant to meet your business needs.</p>
            <WebScraper onSummaryGenerated={handleSummaryGenerated} onError={handleScrapingError} />
          </div>
          {error && <div className="error-message">{error}</div>}
          <form className="home-form" onSubmit={handleSubmit}>
            <label htmlFor="businessDescription">Business Description:</label>
            <textarea
              id="businessDescription"
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
              placeholder="Provide a detailed description of your business, including its core services, target audience, and any other relevant details..."
              rows="4"
            />
            <button type="submit" className="submit-button">Create Your AI Assistant</button>
          </form>
        </section>
      </main>

      <footer className="home-footer">
        <p>&copy; 2023 AI Assistant Prompt Generator. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
