import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Assistants from './components/Assistants';
import Questions from './components/Questions';
import Header from './components/Header';
import './styles/global.css';

function App() {
  return (
    <Router basename="/assistant-creator">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/assistants" element={<Assistants />} />
        <Route path="/questions" element={<Questions />} />
      </Routes>
    </Router>
  );
}

export default App;
