import React, { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import styles from './NewAssistant.module.css';
import PdfFunnel from './PdfFunnel';

const API_URL = process.env.REACT_APP_API_URL;

const NewAssistant = ({ assistantTitle, systemPrompt, additionalInstructions, getAccessToken }) => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const sendRequest = async (data, accessToken, retries = 3) => {
    try {
      const response = await axios.post(
        `${API_URL}/chat`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          timeout: 120000,
        }
      );
      return response.data;
    } catch (error) {
      if (retries > 0 && (error.code === 'ECONNABORTED' || error.response?.status === 504)) {
        console.log(`Retrying request. Attempts left: ${retries - 1}`);
        await new Promise(resolve => setTimeout(resolve, 5000));
        return sendRequest(data, accessToken, retries - 1);
      }
      throw error;
    }
  };

  const handleSend = async () => {
    if (userInput.trim() === '') return;

    const newMessage = { sender: 'user', text: userInput };
    setChatHistory(prevHistory => [...prevHistory, newMessage]);
    setUserInput('');
    setIsLoading(true);

    try {
      const accessToken = await getAccessToken();
      const data = {
        messages: [...chatHistory, newMessage],
        system_prompt: systemPrompt,
        additional_instructions: Array.isArray(additionalInstructions) ? additionalInstructions : [additionalInstructions],
      };

      const response = await sendRequest(data, accessToken);

      const assistantReply = { sender: 'assistant', text: response.reply };
      setChatHistory(prevHistory => [...prevHistory, assistantReply]);
    } catch (error) {
      console.error('Error communicating with the assistant:', error);
      alert('An error occurred while communicating with the assistant. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.newAssistantContainer}>
      <div className={styles.chatSection}>
        <div className={styles.chatHeader}>
          <h2 className={styles.assistantTitle}>{assistantTitle}</h2>
        </div>
        <div className={styles.chatBody}>
          <div className={styles.chatMessages} ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div key={index} className={`${styles.message} ${styles[message.sender]}`}>
                <div className={styles.messageContent}>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className={`${styles.message} ${styles.assistant}`}>
                <div className={styles.messageContent}>
                  <div className={styles.loader}></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.chatInput}>
          <Form onSubmit={(e) => e.preventDefault()} className={styles.inputForm}>
            <Form.Control
              as="textarea"
              rows={1}
              value={userInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Type your message here..."
              className={styles.userInput}
            />
            <Button 
              variant="primary" 
              onClick={handleSend} 
              disabled={isLoading} 
              className={styles.sendButton}
              data-o-account-activity={`Sent Message to ${assistantTitle}`}
            >
              Send
            </Button>
          </Form>
        </div>
      </div>
      <div className={styles.pdfFunnelSection}>
        <div className={styles.pdfFunnelContent}>
          <h3 className={styles.pdfFunnelTitle}>Enhance Your Experience</h3>
          <div className={styles.pdfFunnelGuide}>
            <h4 className={styles.pdfFunnelGuideTitle}>10 Ways to Maximize Your AI Assistant's Potential</h4>
            <ul className={styles.pdfFunnelBenefits}>
              <li>Boost productivity</li>
              <li>Enhance decision-making</li>
              <li>Streamline workflow</li>
              <li>Gain competitive edge</li>
            </ul>
          </div>
          <div className={styles.pdfFunnelCta}>
            <p>Unlock valuable insights tailored for {assistantTitle} users</p>
            <PdfFunnel 
              businessDescription={systemPrompt} 
              assistantPrompt={additionalInstructions}
              data-o-account-activity={`Interacted with PDF Funnel for ${assistantTitle}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAssistant;
