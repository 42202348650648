import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Form, 
  Button, 
  Modal, 
  Spinner, 
  InputGroup, 
  FormControl, 
  ListGroup 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import NewAssistant from './NewAssistant';
import './Assistants.css';
import assistantsData from './AssistantsData';

const API_URL = process.env.REACT_APP_API_URL;

const Assistants = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [assistant, setAssistant] = useState('');
  const [assistantPrompt, setAssistantPrompt] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [waitingForAuth, setWaitingForAuth] = useState(false);

  useEffect(() => {
    const checkAuthStatus = () => {
      if (window.Outseta) {
        window.Outseta.getUser().then(user => {
          setIsAuthenticated(!!user);
          setUserProfile(user);
          if (user && waitingForAuth) {
            setWaitingForAuth(false);
            generateQuestions(selectedAssistant.title, selectedAssistant.prompt);
          }
        });
      }
    };

    checkAuthStatus();
    window.addEventListener('outseta-auth', checkAuthStatus);

    // Load business description from localStorage
    const storedBusinessDescription = localStorage.getItem('businessDescription');
    if (storedBusinessDescription) {
      setBusinessDescription(storedBusinessDescription);
    }

    return () => {
      window.removeEventListener('outseta-auth', checkAuthStatus);
    };
  }, [selectedAssistant, waitingForAuth]);

  const categories = Object.keys(assistantsData);

  const handleCategoryClick = (category) => {
    setActiveCategory(category.toLowerCase().replace(/\s+/g, '-'));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleLogin = () => {
    if (window.Outseta) {
      window.Outseta.auth.open('login');
    }
  };

  const handleSignUp = () => {
    if (window.Outseta) {
      window.Outseta.auth.open('register');
    }
  };

  const handleLogout = () => {
    if (window.Outseta) {
      window.Outseta.logout();
    }
  };

  const getAccessToken = async () => {
    if (window.Outseta) {
      try {
        const token = await window.Outseta.getAccessToken();
        return token;
      } catch (error) {
        console.error('Error getting access token:', error);
        return null;
      }
    }
    return null;
  };

  const generateQuestions = async (selectedAssistant, selectedAssistantPrompt) => {
    console.log('Generating questions for:', selectedAssistant);
    console.log('Assistant prompt:', selectedAssistantPrompt);
    console.log('Business description:', businessDescription);

    if (!isAuthenticated) {
      setSelectedAssistant({ title: selectedAssistant, prompt: selectedAssistantPrompt });
      setWaitingForAuth(true);
      handleLogin();
      return;
    }

    setQuestions([]);
    setAnswers({});
    setSystemPrompt('');
    setAdditionalInstructions('');
    setCurrentPage(0);

    setAssistant(selectedAssistant);
    setAssistantPrompt(selectedAssistantPrompt);
    setLoading(true);

    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error('No access token available');
      }

      console.log('Sending request to generate questions');
      const response = await axios.get(`${API_URL}/generate`, {
        params: {
          assistant: selectedAssistant,
          assistant_prompt: selectedAssistantPrompt,
          business_description: businessDescription,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        timeout: 300000 // 5 minutes timeout
      });

      console.log('Response received:', response.data);
      setQuestions(response.data.questions || []);
      setShowQuestionsModal(true);
      setCurrentPage(0);

      await generateCustomPrompts(selectedAssistant, selectedAssistantPrompt);
    } catch (error) {
      console.error('Error generating questions:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      alert('An error occurred while generating questions. Please check the console for more details and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateMore = async () => {
    console.log('Generating more questions');
    if (!isAuthenticated) {
      handleLogin();
      return;
    }

    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error('No access token available');
      }

      const visibleQuestions = questions.slice(currentPage * 5, (currentPage + 1) * 5);
      const visibleAnswers = visibleQuestions.reduce((acc, question) => {
        acc[question.question] = answers[question.question] || '';
        return acc;
      }, {});

      console.log('Sending request to generate more questions');
      const response = await axios.post(`${API_URL}/more_questions`, {
        assistant,
        assistant_prompt: assistantPrompt,
        business_description: businessDescription,
        qa_pairs: visibleQuestions.map(q => ({ question: q.question, answer: visibleAnswers[q.question] })),
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        timeout: 300000 // 5 minutes timeout
      });

      console.log('Response received:', response.data);

      if (response.data.status === 'success') {
        const newPairs = response.data.qa_pairs || [];

        const updatedQuestions = [...questions, ...newPairs];
        const updatedAnswers = { ...answers, ...visibleAnswers };

        setQuestions(updatedQuestions);
        setAnswers(updatedAnswers);
        setCurrentPage(Math.floor((updatedQuestions.length - 1) / 5));

        await generateCustomPrompts(assistant, assistantPrompt);
      } else {
        console.warn('No new questions generated.');
      }
    } catch (error) {
      console.error('Error generating more questions:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      alert('An error occurred while generating more questions. Please check the console for more details and try again.');
    } finally {
      setLoading(false);
    }
  };

  const generateCustomPrompts = async (assistant, assistantPrompt, retryCount = 0) => {
    console.log('Generating custom prompts for:', assistant);
    console.log('Assistant prompt:', assistantPrompt);
    if (!isAuthenticated) {
      handleLogin();
      return;
    }

    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error('No access token available');
      }

      console.log('Sending request to generate custom prompts');
      const response = await axios.post(`${API_URL}/generate_prompts`, {
        assistant: assistant,
        assistant_prompt: assistantPrompt,
        business_description: businessDescription,
        qa_pairs: questions.map((q) => ({ question: q.question, answer: answers[q.question] || '' })),
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        timeout: 300000 // 5 minutes timeout
      });
  
      console.log('Response received:', response.data);

      if (response.data.status === 'success') {
        setSystemPrompt(response.data.system_prompt || '');
  
        if (Array.isArray(response.data.additional_instructions)) {
          // Remove any existing numbering and join with newlines
          const formattedInstructions = response.data.additional_instructions
            .map(instruction => instruction.replace(/^\d+\.\s*/, '').trim())
            .join('\n');
          setAdditionalInstructions(formattedInstructions);
        } else {
          console.error('Additional instructions are not in the expected format:', response.data.additional_instructions);
          setAdditionalInstructions('Error: Unable to load additional instructions. Please try again.');
        }
      } else {
        throw new Error('Failed to generate custom prompts');
      }
    } catch (error) {
      console.error('Error generating custom prompts:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      if (retryCount < 3) {
        console.warn(`Retrying to generate custom prompts... Attempt ${retryCount + 1}`);
        // Add a delay before retrying
        await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
        await generateCustomPrompts(assistant, assistantPrompt, retryCount + 1);
      } else {
        alert('An error occurred while generating custom prompts. Please check the console for more details and try again.');
      }
    }
  };
  
  const handleCreateAssistant = () => {
    if (!isAuthenticated) {
      handleLogin();
      return;
    }

    console.log("Assistant created with the following prompts:", {
      systemPrompt,
      additionalInstructions,
      questions,
      answers
    });
    setShowQuestionsModal(false);
    setShowChatModal(true);
  };
  
  const filteredAssistants = Object.entries(assistantsData).flatMap(([category, categoryAssistants]) =>
    categoryAssistants
      .filter(
        (assistant) =>
          (activeCategory === 'all' || category === activeCategory) &&
          (assistant.title.toLowerCase().includes(searchTerm) || assistant.description.toLowerCase().includes(searchTerm))
      )
      .map((assistant) => ({ ...assistant, category }))
  );
  
  const renderCategory = (category, assistants) => (
    <div key={category} className="category-section">
      <h3 className="category-title">{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
      <div className="assistants-list">
        <Row>
          {assistants.map((assistant, index) => (
            <Col md={6} key={index} className="mb-4">
              <Card 
                className="h-100 assistant-card" 
                onClick={() => generateQuestions(assistant.title, assistant.prompt)}
                {...(isAuthenticated ? { 'data-o-account-activity': `Selected Assistant: ${assistant.title}` } : {})}
              >
                <Card.Body>
                  <Card.Title>{assistant.title}</Card.Title>
                  <Card.Text>{assistant.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
  
  return (
    <Container className="assistants-container my-5">
      <header className="assistants-header">
        <h1>CREATE YOUR ASSISTANT</h1>
        <h2>Choose a Starting Point</h2>
        <p>
          Pick a pre-made foundation and customize it to fit your business requirements and align with your distinct goals.
        </p>
        <div className="auth-buttons">
          {!isAuthenticated ? (
            <>
              <Button onClick={handleLogin} variant="outline-primary" className="mr-2">Login</Button>
              <Button onClick={handleSignUp} variant="primary">Sign Up</Button>
            </>
          ) : (
            <>
              <a href="https://sombra-1.outseta.com/profile#o-authenticated" className="btn btn-outline-primary mr-2" data-outseta-modal="true">Profile</a>
              <Button onClick={handleLogout} variant="primary">Logout</Button>
            </>
          )}
        </div>
      </header>
      <main className="assistants-main">
        <Row>
          <Col md={3} className="mb-4">
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search Templates"
                aria-label="Search Templates"
                onChange={handleSearch}
              />
              <Button variant="primary">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
            <ListGroup>
              {categories.map((category, index) => (
                <ListGroup.Item
                  key={index}
                  active={category.toLowerCase().replace(/\s+/g, '-') === activeCategory}
                  onClick={() => handleCategoryClick(category)}
                  action
                >
                  {category}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={9}>
            <div className="assistants-content">
              {activeCategory === 'all'
                ? Object.entries(assistantsData).map(([category, categoryAssistants]) =>
                    renderCategory(category, categoryAssistants)
                  )
                : renderCategory(activeCategory, filteredAssistants)}
            </div>
          </Col>
        </Row>
        <Modal show={loading} centered>
          <Modal.Header>
            <Modal.Title>Generating Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className="mt-3">Generating questions and custom prompts...</p>
          </Modal.Body>
        </Modal>
        <Modal show={showQuestionsModal} onHide={() => setShowQuestionsModal(false)} size="xl" dialogClassName="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>Optimize your AI Assistant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <h4>Questions</h4>
                {questions.length > 0 ? (
                  <Form>
                    {questions.slice(currentPage * 5, (currentPage + 1) * 5).map((qa, index) => (
                      <div key={index} className="mb-3">
                        <h5>{qa.question}</h5>
                        <Form.Group controlId={`answer_${index}`}>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Write your answer here..."
                            value={answers[qa.question] || ''}
                            onChange={(e) => setAnswers({ ...answers, [qa.question]: e.target.value })}
                            {...(isAuthenticated ? { 'data-o-account-activity': 'Answered Question' } : {})}
                          />
                        </Form.Group>
                      </div>
                    ))}
                  </Form>
                ) : (
                  <p>No questions available.</p>
                )}
              </Col>
              <Col md={6}>
                <h4>Generated Prompts</h4>
                <Form.Group className="mb-3">
                  <Form.Label>System Prompt</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    rows={5} 
                    value={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Additional Instructions</Form.Label>
                  <ol>
                    {additionalInstructions.split('\n').map((instruction, index) => (
                      <li key={index}>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={instruction}
                          onChange={(e) => {
                            const newInstructions = additionalInstructions.split('\n');
                            newInstructions[index] = e.target.value;
                            setAdditionalInstructions(newInstructions.join('\n'));
                          }}
                          style={{ marginBottom: '10px' }}
                        />
                      </li>
                    ))}
                  </ol>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="primary" 
              onClick={handleGenerateMore}
              {...(isAuthenticated ? { 'data-o-account-activity': 'Generated More Questions' } : {})}
            >
              Generate More
            </Button>
            <Button 
              variant="success" 
              onClick={handleCreateAssistant}
              {...(isAuthenticated ? { 'data-o-account-activity': 'Created Assistant' } : {})}
            >
              Create Assistant
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showChatModal} onHide={() => setShowChatModal(false)} size="xl" dialogClassName="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>Chat with Your {assistant}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewAssistant
              assistantTitle={assistant}
              systemPrompt={systemPrompt}
              additionalInstructions={additionalInstructions}
              getAccessToken={getAccessToken}
            />
          </Modal.Body>
        </Modal>
      </main>
    </Container>
  );
};

export default Assistants;
